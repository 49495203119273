<template>
  <div>
    <b-card title="GGE GGUL에 오신것을 환영합니다">
      <b-card-text>
        {{ mainNotice }}
      </b-card-text>
<!--      <b-card-text> 🚀 GGE GGUL은 아래 사항을 준수합니다 </b-card-text>-->
<!--      <b-card-text class="text-warning mb-lg-0">-->
<!--        1. 안전한 놀이터-->
<!--      </b-card-text>-->
<!--      <b-card-text class="text-warning mb-lg-0">-->
<!--        2. 투명한 관리-->
<!--      </b-card-text>-->
<!--      <b-card-text class="text-warning ">-->
<!--        3. 안정적 운영-->
<!--      </b-card-text>-->
<!--      <b-card-text class="mb-lg-0"> 🚀 최신 소식은 다음 <b-link-->
<!--        href="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation/"-->
<!--        target="_blank"-->
<!--      >-->
<!--        공지사항-->
<!--      </b-link> 을 확인해 주시기 바랍니다.</b-card-text>-->
<!--      <b-card-text> 🚀 운영 수칙과 게임 방법은 다음-->
<!--        <b-link :to="{name:'faq'}">-->
<!--          FAQ-->
<!--        </b-link> 를 확인해 주시기 바랍니다.</b-card-text>-->
    </b-card>

    <b-row class="match-height">
      <b-col
        class="cards"
        md="3"
        sm="6"
      >
        <b-card
          class="imgs text-center cursor-pointer"
          img-top
          :img-src="imageUrlPrematch"
          @click="$router.push({name:'sports-prematch',params:{}})"
          @mouseover="onMouseOver"
          @mouseout="onMouseOut"
        >
          <h4> Sports Prematch </h4>
          <b-card-text class="mt-1">
            프리매치 게임을 즐기세요~
          </b-card-text>
        </b-card>
      </b-col>

      <b-col
        class="cards"
        md="3"
        sm="6"
      >
        <b-card
          class="imgs text-center cursor-pointer"
          img-top
          :img-src="imageUrlSpecial"
          @click="$router.push({name:'sports-special',params:{}})"
          @mouseover="onMouseOver"
          @mouseout="onMouseOut"
        >
          <h4> Sports Special </h4>
          <b-card-text class="mt-1">
            스페셜 이벤트 경기 배팅
          </b-card-text>
        </b-card>
      </b-col>

      <b-col
        class="cards"
        md="3"
        sm="6"
      >
        <b-card
          class="imgs text-center cursor-pointer"
          img-top
          :img-src="imageUrlInplay"
          @click="$router.push({name:'sports-inplay',params:{}})"
          @mouseover="onMouseOver"
          @mouseout="onMouseOut"
        >
          <h4> Sports InPlay </h4>
          <b-card-text class="mt-1">
            실시간 스포츠 경기 배팅
          </b-card-text>
        </b-card>
      </b-col>

      <b-col
        class="cards"
        md="3"
        sm="6"
      >
        <b-card
          class="imgs text-center cursor-pointer"
          img-top
          :img-src="imageUrlCasino"
          @click="$router.push({name:'casino-cards',params:{}})"
          @mouseover="onMouseOver"
          @mouseout="onMouseOut"
        >
          <h4> Casino Cards </h4>
          <b-card-text class="mt-1">
            바카라, 7포커, 블랙잭 등 호텔 카드게임 제휴
          </b-card-text>
        </b-card>
      </b-col>

      <b-col
        class="cards"
        md="3"
        sm="6"
      >
        <b-card
          class="imgs text-center cursor-pointer"
          img-top
          :img-src="imageUrlSlot"
          @click="$router.push({name:'casino-slot',params:{}})"
          @mouseover="onMouseOver"
          @mouseout="onMouseOut"
        >
          <h4> Casino Slot </h4>
          <b-card-text class="mt-1">
            100% 꽝 없는 슬롯 머신의 세계로 오세요
          </b-card-text>
        </b-card>
      </b-col>

      <b-col
        class="cards"
        md="3"
        sm="6"
      >
        <b-card
          class="imgs text-center cursor-pointer"
          img-top
          :img-src="imageUrlResult"
          @click="$router.push({name:'result',params:{}})"
          @mouseover="onMouseOver"
          @mouseout="onMouseOut"
        >
          <h4> Result </h4>
          <b-card-text class="mt-1">
            경기 결과를 확인할 수 있습니다
          </b-card-text>
        </b-card>
      </b-col>

      <b-col
        class="cards"
        md="3"
        sm="6"
      >
        <b-card
          class="imgs text-center cursor-pointer"
          img-top
          :img-src="imageUrlDeposit"
          @click="$router.push({name:'deposit',params:{}})"
          @mouseover="onMouseOver"
          @mouseout="onMouseOut"
        >
          <h4> Deposit </h4>
          <b-card-text class="mt-1">
            캐쉬 충전 메뉴입니다
          </b-card-text>
        </b-card>
      </b-col>

      <b-col
        class="cards"
        md="3"
        sm="6"
      >
        <b-card
          class="imgs text-center cursor-pointer"
          img-top
          :img-src="imageUrlExchange"
          @click="$router.push({name:'exchange',params:{}})"
          @mouseover="onMouseOver"
          @mouseout="onMouseOut"
        >
          <h4> Exchange </h4>
          <b-card-text class="mt-1">
            환전 신청 메뉴입니다
          </b-card-text>
        </b-card>
      </b-col>

    </b-row>

    <!-- contact us -->
    <b-row class="mt-5 pt-75 text-center">
      <b-col cols="12">
        <h2>Contact Us</h2>
        <b-card-text class="mb-3">
          도움이 필요할 경우 24시간 대기 운영진이 대응해 드립니다.
        </b-card-text>
      </b-col>
      <b-col sm="6">
        <b-card
          class="shadow-none py-1 faq-contact-card"
        >
          <b-avatar
            size="42"
            rounded
            variant="light-primary"
            class="mb-2"
          >
            <feather-icon
              icon="PhoneCallIcon"
              size="18"
            />
          </b-avatar>
          <h4>+ (810) 2548 2568</h4>
          <span class="text-body">We are always happy to help!</span>
        </b-card>
      </b-col>
      <b-col sm="6">
        <b-card
          class="shadow-none py-1 faq-contact-card"
        >
          <b-avatar
            size="42"
            rounded
            variant="light-primary"
            class="mb-2"
          >
            <feather-icon
              icon="MailIcon"
              size="18"
            />
          </b-avatar>
          <h4>hello@help.com</h4>
          <span class="text-body">Best way to get answer faster!</span>
        </b-card>
      </b-col>
    </b-row>
    <!--/ contact us -->
    <b-col>
    </b-col>
    <popup />
  </div>
</template>

<script>
import axios from 'axios'
import {
  BRow, BCol, BCard, BCardText, BLink, BAvatar,
} from 'bootstrap-vue'

import Popup from '@/views/Popup.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'
import { createNamespacedHelpers } from 'vuex'
import { FETCH_SET_BASIC } from '@/store/notice/action'
import { UPDATE_USER_CASH } from '@/store/user/action'


const noticeStore = createNamespacedHelpers('noticeStore')
const userStore = createNamespacedHelpers('userStore')

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,

    Popup,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      mainNotice: '',
      noticeContent: [],
      imageUrlPrematch: 'https://agress-assets.s3.ap-northeast-2.amazonaws.com/thumbnail/prematch.png',
      imageUrlSpecial: 'https://agress-assets.s3.ap-northeast-2.amazonaws.com/thumbnail/special.png',
      imageUrlInplay: 'https://agress-assets.s3.ap-northeast-2.amazonaws.com/thumbnail/inplay.png',
      imageUrlCasino: 'https://agress-assets.s3.ap-northeast-2.amazonaws.com/thumbnail/casino.png',
      imageUrlSlot: 'https://agress-assets.s3.ap-northeast-2.amazonaws.com/thumbnail/slot.png',
      imageUrlResult: 'https://agress-assets.s3.ap-northeast-2.amazonaws.com/thumbnail/result.png',
      imageUrlDeposit: 'https://agress-assets.s3.ap-northeast-2.amazonaws.com/thumbnail/deposit.png',
      imageUrlExchange: 'https://agress-assets.s3.ap-northeast-2.amazonaws.com/thumbnail/exchange.png',
      audio: null, // audio 객체를 저장할 변수
      isPlaying: false, // 음악이 재생 중인지 여부를 나타내는 변수
      playCount: 0, // 추가: 재생 횟수를 기록하는 변수
      maxPlayCount: 1, // 추가: 최대 재생 횟수
      alarmSettings: [], // alarmSettings 초기화
    }
  },
  computed: {
    ...noticeStore.mapGetters([
      'fetchSetBasic',
    ]),
  },
  async mounted() {
    this.audio = this.$refs.backgroundAudio
    await this.requestAudioPermission()
    await this.fetchData()
    await this.socketListener()
  },
  beforeDestroy() {
    this.$socket.emit('handleMenuEntry', {
      site: this.$site,
      userid: JSON.parse(localStorage.getItem('userData')).userid,
      menu: '',
      menuCategory: '',
    })
  },
  methods: {
    ...noticeStore.mapActions({
      $fetchSetBasic: FETCH_SET_BASIC,
    }),
    ...userStore.mapActions({
      $updateUserCash: UPDATE_USER_CASH,
    }),
    async requestAudioPermission() {

      // 로컬스토리지에서 사용자가 상호작용했는지 확인
      const hasInteracted = localStorage.getItem('hasInteracted')

      // 상호작용이 없으면 첫 상호작용을 대기
      if (!hasInteracted) {
        document.addEventListener('click', this.enableAudio)
      }

      // 페이지 로드 시 사용자에게 Notification 권한 요청
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          // localStorage.setItem('hasInteracted', 'true')
          this.showToast('알림 권한이 허용되었습니다.', '', 'top-center')
        } else {
          console.log('알림 권한이 거부되었습니다.')
        }
      }).catch(error => {
        console.log('알림 권한 요청 중 에러 발생:', error)
      })
    },
    enableAudio() {
      // 첫 클릭 시 오디오 재생 허용 및 상호작용 기록 저장
      if (this.audio) {
        this.audio.play().then(() => {
          console.log('오디오가 재생됩니다.')
          // 사용자가 상호작용했음을 로컬스토리지에 저장
          localStorage.setItem('hasInteracted', 'true')
          // 재생 후 이벤트 리스너 제거
          document.removeEventListener('click', this.enableAudio)
        }).catch(error => {
          console.log('오디오 재생 중 에러 발생:', error)
        })
      }
    },
    async fetchData() {
      // const mySite = this.userData.site
      this.$socket.emit('handleMenuEntry', {
        site: this.$site,
        userid: JSON.parse(localStorage.getItem('userData')).userid,
        menu: 'home',
        menuCategory: '',
      })
      await this.$fetchSetBasic({
        site: this.$site,
      }).then(res => {
        console.log('this.fetchSetBasic == ', this.fetchSetBasic)
        this.mainNotice = this.fetchSetBasic[0].mainNotice
      })
    },
    socketListener() {
      // localStorage 업데이트 수신
      console.log('listener on')

      // 1.메세지 수신
      this.$socket.on('alarmsMassageToClient', data => {
        console.log('Socket: alarmsMassageToClient')
        // 사운드를 실행합니다.
        this.playCount = 0 // reset play count
        this.autoplayVideo('신규메세지')
        // 알람 전송
        const target = {
          avatar: '쪽지',
          title: '신규메세지가 도착했습니다',
          subtitle: '메세지를 확인해주세요',
          type: 'primary',
          target: 'notificationStore/addNotification',
          route: '/messages',
        }
        this.alarmMsg(target)
      })

      // 2. User Update LocalStorage 수신
      this.$socket.on('updateLocalStorageUserInfo', async data => {
        console.log('Socket: updateLocalStorageUserInfo')
        // 사운드를 실행합니다.
        this.playCount = 0 // reset play count
        this.autoplayVideo('유저캐쉬변경')
        // 알람 전송
        const target = {
          avatar: '유저캐쉬변경',
          title: '머니/캐쉬정보가 갱신되었습니다',
          subtitle: '메세지를 확인해주세요',
          type: 'primary',
          target: 'notificationStore/addNotification',
          route: '/messages',
        }
        this.alarmMsg(target)
        if (store.getters['authStore/isAuthenticated']) {
          console.log('isAuthenticated ==> ', store.getters['authStore/isAuthenticated'])
          // store.dispatch('authStore/updateLocalStorage', data)
          await this.$updateUserCash({
            site: this.$site,
            userid: this.userData.userid,
          })
        }
      })
    },
    autoplayVideo(target) {
      this.audio = new Audio(`https://agress-assets.s3.ap-northeast-2.amazonaws.com/sounds/${target}.mp3`)
      this.audio.addEventListener('ended', this.replaySound) // 음악이 끝나면 재생 횟수를 체크하여 다시 시작

      // 로컬스토리지에서 상호작용 여부를 확인하여 바로 재생할지 결정
      const hasInteracted = localStorage.getItem('hasInteracted')
      if (hasInteracted) {
        const result = this.audio.play()
        this.isPlaying = true
        return result
      } else {
        // 상호작용이 없으면 첫 클릭 시 오디오 재생 대기
        document.addEventListener('click', () => {
          const result = this.audio.play()
          this.isPlaying = true
          return result
        }, { once: true })
      }
      return null
    },
    replaySound() {
      this.playCount += 1 // Increase play count
      if (this.playCount < this.maxPlayCount) {
        this.audio.play() // Play again
      } else {
        this.stopSound()
      }
    },
    stopSound() {
      this.audio.pause()
      this.audio.currentTime = 0
      this.audio.removeEventListener('ended', this.replaySound)
      this.audio = null
      this.isPlaying = false
    },
    alarmMsg(target) {
      const msg = {
        id: Math.floor(Math.random() * 1000) + 1,
        title: target.title,
        avatar: target.avatar,
        subtitle: target.subtitle,
        type: target.type,
        route: target.route,
      }
      store.dispatch(target.target, msg)
    },
    // async getNotice() {
    //   const vm = this
    //   await axios.get('https://mocki.io/v1/f33cebea-6156-4666-b713-37b61311f9d6')
    //   // axios.get('https://js\onplaceholder.typicode.com/users/')
    //     .then(response => {
    //       vm.noticeContent = response.data[0].data
    //     })
    //     .catch(error => {
    //       console.log(error)
    //     })
    //
    //   const h = this.$createElement
    //   // Using HTML string
    //   // const titleVNode = h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
    //   // More complex structure
    //   const messageVNode = h('div', { domProps: { innerHTML: this.noticeContent } })
    //   // We must pass the generated VNodes as arrays
    //   this.$bvModal.msgBoxOk([messageVNode], {
    //     // title: [titleVNode],
    //     buttonSize: 'sm',
    //     centered: true,
    //     size: 'sm',
    //   })
    // },
    onMouseOver(event) {
      // 마우스를 올렸을 때 이미지를 확대하는 효과를 추가
      event.target.classList.add('hover-effect')
    },
    onMouseOut(event) {
      // 마우스를 내렸을 때 효과를 제거
      event.target.classList.remove('hover-effect')
    },
    showToast(title, subtitle, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text: subtitle,
          icon: 'BellIcon',
          variant: 'success',
        },
      },
      {
        position,
        // timeout: 8000,
      })
    },
  },
}
</script>

<style lang="scss">

.modal-dialog{
    position: relative;
    display: table; /* This is important */
    overflow-y: auto;
    overflow-x: auto;
    width: auto;
    min-width: 300px;
}
.imgs {
  overflow: hidden; /* 이미지가 확대되었을 때 카드 밖으로 넘치지 않도록 설정 */
}
.hover-effect {
  animation: flash 0.3s ease-in-out;
  transform: scale(1.07); /* 이미지를 1.1배 확대 */
  transition: transform 0.3s ease-in-out; /* 트랜지션 효과 추가 */
}
/* 반짝거리는 효과를 위한 키프레임 애니메이션 정의 */
@keyframes flash {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

/* Responsive: Use a vertical column if under 805px wide */
@media screen and (max-width: 805px) {
  .cards {
    width: 50% !important;
  }
  .imgs {
    align-content: center;
    //width: 80%;
    //width: 80%;
  }
}
</style>
